import React from 'react'
import Slider from "react-slick"
import fashion01 from '../assets/images/fashion/01.jpg'
import fashion02 from '../assets/images/fashion/07.jpg'
import fashion03 from '../assets/images/fashion/10.jpg'

import "slick-carousel/slick/slick.css";

const SimpleSlider2 = ({children}) => {
    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };
    const style = {
        margin: 0,
        background: "#707070"
    };  
    return(
      <Slider {...settings}>
        <div class="portrait">
          <p class="photo_title">ANNDIRKIZM "LOST YOUTH" 2018SS</p>
          <img src={fashion01} alt="image"/>
        </div>
        <div class="portrait">
          <p class="photo_title">ishita "Do we have any hope in the future?” 2017SS</p>
          <img src={fashion02} alt="image"/>
        </div>
        <div class="portrait">
          <p class="photo_title">Nehanne MIHARA YASUHIRO 2018SS</p>
          <img src={fashion03} alt="image"/>
        </div>
      </Slider>
    )
}

export default SimpleSlider2