import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Fashionnav from '../components/Fashionnav'

import SimpleSlider2 from '../components/FashionSlider'


const Fashion = (props) => {
  const siteTitle = 'Onodera Ryo  | portrait'

  return(
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
      </Helmet>
      <div id="main">
        <div class="portrait">
          <Fashionnav>
            Fashion
          </Fashionnav>
          <SimpleSlider2 />
        </div>
      </div>
    </Layout>
  )
}

export default Fashion